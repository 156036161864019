import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import useAlert from "../hooks/useAlert";
import { useMutation } from "@apollo/client";
import { preventClickBackMsgs } from "../libs/mock";
import { RateConsultationStage, TypeOfRetry } from "../libs/types";
import { ApolloErrorOptions } from "../libs/interface";
import { useGraphQLApi } from "../hooks/useGraphQLApi";
import HealaWordMark from "../assets/heala-wordmark.svg";
import usePreventClickBack from "../hooks/usePreventClickBack";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useConsultationContext } from "../contexts/consultationContext";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  ConsultFactory,
  createConsultationVar,
  destoryStoredVariables,
} from "../utils/funcs";
import {
  Box,
  CircularProgress,
  Rating,
  TextField,
  Typography,
} from "@mui/material";
import {
  createConsultation,
  createDispute,
  updateSatisfaction,
} from "../graphQL/mutations";
import { nem } from "../utils/mock";

/* 

  <p>
          If the Doctor prescribes any medication, you will receive your pickup
          code via SMS. Processing time for medication takes from 1 to 24hrs. If
          you have any questions, please call us at 020213300150, 02013300151,
          or 02013300152. WhatsApp only: 07032276390
        </p>

*/

const RateConsultationStagesContext = createContext<{
  stage: RateConsultationStage | null;
  setStage: React.Dispatch<React.SetStateAction<RateConsultationStage>> | null;
}>({ stage: null, setStage: null });

const WhatIsNext = () => {
  const consultationData = useConsultationContext();
  const { providerId } = ConsultFactory(consultationData);
  const isNemHMO = nem.includes(`${providerId}`);

  return (
    <div className="text-sm max-w-[400px] bg-yellow-50 text-yellow-900 mx-auto my-5 border border-yellow-900 rounded-md p-4 space-y-2">
      <p className="font-bold">What&apos;s next?</p>
      {isNemHMO ? (
        <div className="space-y-2">
          <div className="space-y-2">
            <p>
              If the Doctor prescribes any medication, you will receive a pickup
              code via SMS to present at the pharmacy. Processing time for
              medication takes 30 mins.
            </p>
          </div>

          <div className="space-y-1 my-5 ">
            <p>Drug processing time:</p>
            <ul className=" list-disc font-semibold pl-7">
              <li>Monday - Saturday (8am - 9pm)</li>
              <li>Sunday (1pm - 9pm)</li>
            </ul>
          </div>

          <p className="text-xs">
            If you have any questions, please call us at{" "}
            <a className="underline cursor-pointer" href="tel:02013300150">
              02013300150
            </a>
            ,{" "}
            <a className="underline cursor-pointer" href="tel:02013300151">
              02013300151
            </a>
            , or{" "}
            <a className="underline cursor-pointer" href="tel:02013300152">
              02013300152
            </a>
            . <br />
            WhatsApp(only):{" "}
            <a className="underline cursor-pointer" href="tel:07032276390">
              08077284810
            </a>
          </p>
        </div>
      ) : (
        <p>
          If the Doctor prescribes any medication, you will receive your pickup
          code via SMS. Processing time for medication takes from{" "}
          <b>1 to 24hrs</b>. <br /> If you have any questions,{" "}
          <b>please contact your Provider</b>.
        </p>
      )}
    </div>
  );
};

const RateConsultationForm = () => {
  const navigate = useNavigate();
  const { sendRating } = useGraphQLApi();
  const { consultationId } = useParams();
  const [rateComment, setRateComment] = useState("");
  const [rating, setRating] = useState<number | null>(0);
  const providerLogo = sessionStorage.getItem("providerLogo");

  return (
    <div className="rating_container">
      <div className=" flex justify-center mb-2">
        {/* PROVIDER LOGO */}
        <img
          src={providerLogo || HealaWordMark}
          alt="provider logo"
          className=" max-w-[150px] max-h-[80px]"
        />
      </div>
      <h1 className="waiting_heading">Rate consultation</h1>
      <WhatIsNext />
      <div className="info_container mt-4" style={{ height: "410px" }}>
        <div className="w-full px-4">
          <Typography className="rating_para" component="legend">
            How was your consultation?
          </Typography>

          <div className="flex justify-center rating_div">
            <Rating
              name="simple-controlled"
              value={rating}
              onChange={(event, newValue) => {
                setRating(newValue);
              }}
            />
          </div>
          <div className="flex justify-center mt-6">
            <TextField
              id="outlined-multiline-static"
              multiline
              rows={4}
              label="Comment (optional)"
              variant="outlined"
              value={rateComment}
              onChange={(value) => setRateComment(value.target.value)}
              className=" w-[85%]"
            />
          </div>
          <div className="rating_btn_con">
            <button
              className="rating_btn"
              onClick={() => {
                if (!consultationId) throw Error("Consultation ID not found!");
                sendRating({
                  consultationId: consultationId,
                  review: "",
                  score: rating ?? 0,
                  comment: rateComment,
                }).then(() => {
                  destoryStoredVariables();
                  navigate("/success");
                });
              }}
            >
              Submit
            </button>
            <button
              className="rating_skip"
              color="primary"
              onClick={() => {
                destoryStoredVariables();
                navigate("/success");
              }}
            >
              Skip
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

let intervalId: NodeJS.Timer | undefined = undefined;
const VerifyConsultationSuccess = () => {
  const { consultationId } = useParams();
  const [searchParams] = useSearchParams();
  const issues = searchParams.get("issuefrom");
  const { getErrorMsg, displayAlert } = useAlert();
  const [progress, setProgress] = React.useState(100);
  const setStage = useDispatchRateConsultationStages();
  const iconColor = Boolean(issues) === true ? "#FF5F00" : "#3E5EA9";
  const [reportConsultation, { loading }] = useMutation(updateSatisfaction);

  const report = useCallback(async () => {
    try {
      clearInterval(intervalId);
      await reportConsultation({
        variables: {
          consultationId,
          isSatisfied: false,
          reason: "",
        },
      });

      setStage && setStage("REPORT_CONSULTATION");
    } catch (error) {
      console.error(error);
      const errMsg = getErrorMsg(error as ApolloErrorOptions);
      displayAlert("error", `${errMsg}`);
    }
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => prevProgress - 3.33);
    }, 800);
    intervalId = timer;
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (progress <= 3.33) setStage && setStage("RATE_CONSULTATION");
  }, [progress]);

  return (
    <div className="h-screen w-screen flex justify-center bg-[#f6f7fb] items-center px-3">
      <div className="w-[480px] bg-white p-12 rounded-[20px]">
        <div className="flex justify-end w-full">
          <Box sx={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress variant="determinate" value={progress} />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="caption"
                component="div"
                color="text.secondary"
              >
                {`${Math.round(progress / 3.33)}`}
              </Typography>
            </Box>
          </Box>
        </div>
        <div className="flex flex-col items-center">
          <div
            className={classNames(
              `w-[100px] h-[100px] flex justify-center items-center rounded-[32px]`,
              {
                "bg-[#FF5F0033]": Boolean(issues),
                "bg-blue-100": Boolean(!issues),
              }
            )}
          >
            <ErrorOutlineIcon sx={{ fontSize: 60, color: iconColor }} />
          </div>
          <div className="text-center mt-4 space-y-2 mb-10">
            {Boolean(issues) && (
              <p className="text-[18px] text-[#757886] leading-5">
                {issues === "doctor"
                  ? " This consultation ended due to connection issues from the doctor."
                  : " This consultation ended due to connection issues from you."}
              </p>
            )}
          </div>
          <div className="w-full">
            <p className="text-[#192C48] text-xl text-center">
              {`Thank you for using our platform, was this consultation successful?`}
            </p>
            <div className="w-full mt-5 space-x-2 flex">
              <button
                disabled={loading}
                onClick={() => setStage && setStage("RATE_CONSULTATION")}
                className="w-full h-[46px] bg-[#3E5EA9] text-white rounded-lg"
              >
                Yes
              </button>
              <button
                disabled={loading}
                onClick={() => report()}
                className="w-full h-[46px] border border-[#3E5EA9] text-[#3E5EA9] rounded-lg"
              >
                {loading ? "Loading..." : "No"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ReportConsultation = () => {
  const navigate = useNavigate();
  const { getErrorMsg } = useAlert();
  const { consultationId } = useParams();
  const [errMsg, setErrMsg] = useState("");
  const setStage = useDispatchRateConsultationStages();
  const consultationDetails = useConsultationContext();
  const [reason, setReason] = useState<string | null>(null);
  const textAreaRef = useRef<HTMLInputElement | null>(null);
  const providerLogo = sessionStorage.getItem("providerLogo");
  const [reportConsultation, { loading }] = useMutation(createDispute);

  const isInstant = consultationDetails?.type === "instant";

  const report = useCallback(async () => {
    try {
      const disputeReason = (reason || textAreaRef.current?.value)?.trim();
      if (disputeReason === "" || typeof disputeReason !== "string")
        throw Error("No reason provided yet.");

      await reportConsultation({
        variables: {
          consultationId,
          disputeReason,
        },
      });

      if (isInstant && setStage) {
        setStage("RETRY_CONSULTATION");
      } else {
        navigate("/success", { replace: true });
      }
    } catch (error) {
      console.error(error);
      const errMsg = getErrorMsg(error as ApolloErrorOptions);
      if (!errMsg) {
        const err = error as Error;
        setErrMsg(`${err?.message}`);
      } else {
        setErrMsg(`${errMsg}`);
      }
    }
  }, [reason, textAreaRef]);

  return (
    <div className="h-screen w-screen flex justify-center bg-[#f6f7fb] items-center px-3">
      <div className="max-w-[450px] bg-white rounded-[20px] p-10">
        <div className=" flex justify-center mb-2">
          {/* PROVIDER LOGO */}
          <img
            src={providerLogo || HealaWordMark}
            alt="provider logo"
            className=" max-w-[150px] max-h-[80px]"
          />
        </div>
        <div className="my-5 space-y-3">
          <h1 className="text-2xl text-center">Report this consultation</h1>
          <p className="text-center">
            We are sorry for inconveniences this may have caused. Please tell us
            what went wrong below.
          </p>
        </div>
        <div className="w-full px-4">
          <div className="flex flex-col justify-center mt-6">
            <div className="my-5 space-y-2">
              {[
                {
                  name: "Technical Difficulties",
                  value: "Technical Difficulties",
                },
                { name: "Doctor No-Show", value: "Doctor No-Show" },
                { name: "Miscommunication", value: "Miscommunication" },
                { name: "Others", value: "" },
              ].map((item, idx) => {
                return (
                  <label
                    key={`${item.name}-${idx}`}
                    className="block space-x-2"
                  >
                    <input
                      type="radio"
                      value={item.value}
                      name={item.value}
                      style={{ accentColor: "#3E5EA9" }}
                      onChange={(e) => setReason(e.target.value)}
                      checked={reason === item.value}
                    />
                    <span>{item.name}</span>
                  </label>
                );
              })}
            </div>
            {reason === "" && (
              <TextField
                inputRef={textAreaRef}
                id="outlined-multiline-static"
                multiline
                rows={4}
                label="Reason"
                variant="outlined"
                className=" w-full"
              />
            )}

            {errMsg && (
              <p
                className={classNames(
                  "bg-red-100 text-red-500 text-sm p-2 rounded-md mt-2 transition-all duration-300 ease-in-out",
                  {
                    invisible: Boolean(!errMsg),
                    visible: Boolean(errMsg),
                  }
                )}
              >
                {errMsg}
              </p>
            )}
          </div>
          <div className="rating_btn_con">
            <button
              disabled={loading}
              className="rating_btn"
              onClick={() => {
                try {
                  setErrMsg("");
                  if (!consultationId) throw Error("No consultation ID found!");
                  report();
                } catch (error) {
                  console.error(error);
                  const err = error as Error;
                  setErrMsg(err?.message);
                }
              }}
            >
              {loading ? "Loading..." : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const typeOfRetry = Object.freeze({
  same: "same_doctor",
  another: "another_doctor",
});

const RetryConsultation = () => {
  const navigate = useNavigate();
  const { displayAlert, getErrorMsg } = useAlert();
  const consultationDetails = useConsultationContext();
  const [errMsg, setErrMsg] = useState<string | null>(null);
  const providerLogo = sessionStorage.getItem("providerLogo");
  const [retry, { loading }] = useMutation(createConsultation);
  const [retryType, setRetryType] = useState<TypeOfRetry | null>(null);

  const retryConsultation = useCallback(async () => {
    try {
      if (!consultationDetails)
        throw Error("Previous consultation details not found!");
      if (!retryType) {
        setErrMsg(
          "Please choose a preferred option above to retry consultation"
        );
        return;
      }
      const variables = createConsultationVar(consultationDetails, retryType);
      const { data } = await retry({ variables });
      if (data) {
        const newConsultationId = data?.createConsultation?.consultation?._id;
        localStorage.clear();
        sessionStorage.clear();
        navigate(`/${newConsultationId}`, { replace: true });
      }
    } catch (error) {
      console.error(error);
      const errMsg = getErrorMsg(error as ApolloErrorOptions);
      if (errMsg) displayAlert("error", errMsg);
    }
  }, [retryType, errMsg]);

  return (
    <div className="h-screen w-screen flex justify-center bg-[#f6f7fb] items-center px-3">
      <div className="w-[480px] bg-white p-12 rounded-[20px]">
        <div className="flex flex-col items-center">
          <div className=" flex justify-center mb-2">
            {/* PROVIDER LOGO */}
            <img
              src={providerLogo || HealaWordMark}
              alt="provider logo"
              className=" max-w-[150px] max-h-[80px]"
            />
          </div>
          <div className="my-5 space-y-3">
            <h1 className="text-2xl text-center">Retry Consultation</h1>
            <p className="text-center text-base">
              Would you like to attempt this consultation again?
            </p>
            <div className="space-y-1 pt-5">
              <label
                className="flex items-center space-x-2"
                htmlFor={typeOfRetry.same}
              >
                <input
                  type="radio"
                  className=" text-primary checked:accent-primary w-4 h-4"
                  id={typeOfRetry.same}
                  name="retry_type"
                  value={typeOfRetry.same}
                  onChange={(e) => {
                    setRetryType(e.target.value as TypeOfRetry);
                    setErrMsg(null);
                  }}
                />
                <span>Retry with the same doctor</span>
              </label>
              <label
                className="flex items-center space-x-2"
                htmlFor={typeOfRetry.another}
              >
                <input
                  type="radio"
                  className=" text-primary checked:accent-primary w-4 h-4"
                  id={typeOfRetry.another}
                  name="retry_type"
                  value={typeOfRetry.another}
                  onChange={(e) => {
                    setRetryType(e.target.value as TypeOfRetry);
                    setErrMsg(null);
                  }}
                />
                <span>Try with any available doctor</span>
              </label>
            </div>
            {errMsg && (
              <p className="text-sm py-2 font-medium px-4 bg-red-200 text-red-500 mt-2 rounded-md">
                {errMsg}
              </p>
            )}
          </div>
          <div className="w-full">
            <div className="w-full mt-5 space-x-2 flex">
              <button
                disabled={loading}
                onClick={() => retryConsultation()}
                className="w-full h-[46px] bg-[#3E5EA9] text-white rounded-lg"
              >
                {loading ? "Retrying" : "Retry"}
              </button>
              <button
                disabled={loading}
                onClick={() => {
                  destoryStoredVariables();
                  navigate("/success", { replace: true });
                }}
                className="w-full h-[46px] border border-[#3E5EA9] text-[#3E5EA9] rounded-lg"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const RateConsultation = () => {
  usePreventClickBack(preventClickBackMsgs.RateConsultation);
  const [stage, setStage] = useState<RateConsultationStage | null>(null);

  useEffect(() => {
    const sessionStage = sessionStorage.getItem("rateConsultationStage");
    if (sessionStage) {
      setStage(sessionStage as RateConsultationStage);
    } else {
      // sessionStorage.setItem("rateConsultationStage", stage);
      setStage("VERIFY_SUCCESS");
    }
  }, []);

  useEffect(() => {
    if (stage) sessionStorage.setItem("rateConsultationStage", stage);
  }, [stage]);

  return (
    <RateConsultationStagesContext.Provider
      value={{
        stage,
        setStage: setStage as Dispatch<SetStateAction<RateConsultationStage>>,
      }}
    >
      <div className="px-2">{stage && switchStage(stage)}</div>
    </RateConsultationStagesContext.Provider>
  );
};

const useDispatchRateConsultationStages = () => {
  const context = useContext(RateConsultationStagesContext);
  return context?.setStage;
};

const stages: Record<RateConsultationStage, JSX.Element> = {
  RATE_CONSULTATION: <RateConsultationForm />,
  VERIFY_SUCCESS: <VerifyConsultationSuccess />,
  REPORT_CONSULTATION: <ReportConsultation />,
  RETRY_CONSULTATION: <RetryConsultation />,
};

const switchStage = (stage: RateConsultationStage) => stages[stage];

export default RateConsultation;
